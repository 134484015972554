.main-section{
    background: linear-gradient(180deg, rgba(122, 58, 93, 0) 0%, #000000 100%);
}
.line{
    position: relative;
    background-color: #31354700;
    top: 0;
    /* height: 14.5px; */
    width: 100%;
    border-bottom: 1px solid rgba(170, 170, 170, 0.537);
    box-shadow: 0 5px 300px 150px #000000;
}
.home-header{
    position: relative;
    top: 30px;
    min-height:calc(100vh - 100px);
    color: #fff;
    width: 100%;
    background: linear-gradient(180deg, rgba(122, 58, 93, 0) 0%, #000000 100%);
    background-repeat: no-repeat;
    background-color: rgba(18, 4, 18, 0.962);
    background-image:url('../../asset/grapicline.png');
    background-size: cover;
    padding-bottom: 50px;
}
.heading{
position: relative;
font-size: 64px;
font-weight: 700;
margin: auto 20px;
padding-top: 100px;
line-height: 72px;
}
.sub-heading{
    color: #A0A3C4;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 60px;
}

.launch-btn{
    padding: 15px 30px;
    font-size: 18px;
    border: 2px solid rgb(248, 207, 207);
    border-radius: 10px;
    box-shadow: 0 5px 20px 2px rgb(105, 105, 157);
    color: #fff;
    font-weight: 500px;
    margin-top: 50px;
    background-image: linear-gradient(-80deg,rgb(223, 1, 171),rgb(226, 9, 117),rgb(199, 19, 193),rgb(97, 86, 213),rgb(136, 210, 222));
    text-decoration: unset;
    display: inline-block;
}

.launch-btn:hover{
    /* background-image: linear-gradient(105deg,rgb(223, 1, 171),rgb(226, 9, 117),rgb(199, 19, 193),rgb(97, 86, 213),rgb(136, 210, 222));
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    box-shadow: 0 5px 20px 2px rgb(161 56 82); */
    background: #4F95DA;
    transition: 1s ease-in-out;
}

/* .launch-btn:active{
    box-shadow: 0 5px 20px 2px rgb(161 56 82);
} */

.grid-box{
    margin-top: 50px;

}
.hom-box{
    margin: 0 auto;
    background-color: transparent;
    border:1.5px solid #313547 ;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    margin: 20px;
    padding-left: 20px;
}
.hom-box img{
    width: 80px;
}
.grid-box-smt{
    position: relative;
    font-size: 16px;
    color: #A0A3C4;
    font-weight: 400;
    bottom: -16px;
    line-height: 24px;
}
.grid-box-lgt{
    position: relative;
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    top: -8px;
    line-height: 32px;
}
@media only screen and (max-width:792px){
    .heading{
        font-size: 40px;
        font-weight: 700;
        margin: auto 20px;
        /* margin-top: 150px; */
        }
}
.home-second-part{
    position: relative;
    background-color: #0A0B11;
    padding: 60px 0;
    /* box-shadow: 5px 0 300px 150px  #000000; */
    border-top: 1px solid #313547;
    border-bottom: 1px solid #313547;
    z-index: 22;
}
.rss-part{
    text-align: left;
    margin: 20px;
}
.ico-text-inline{
    display: flex;
    align-items: center;
}
.rss-text{
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin-left: 21px;
    line-height: 32px;
}
.rss-para{
    color: #aaa;
    position: relative;
    top: -15px;
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
}

/* ------------------------explore// */

.explore-section{
    position: relative;
    background-color:#14141C ;
    padding: 60px 0;
    border-bottom: 1px solid #313547;
}
.explore-text{
    color:rgba(217, 78, 128, 1);
}
.explore-heading{
    color: #fff;
}
.explore-card{
    text-align: left;
    background-color: #0A0B11;
    color: #fff;
    border-radius: 5px;
    border: 1px solid #313547;
    padding: 15px;
    margin: 20px;
    padding-bottom: 28px;
    padding-left: 21px;
    /* display: table-cell; */
}
.card-des{
    font-size: 1.2rem;
}
/* .card-btns{
    position: absolute;
} */
.small-text{
    color: #999;
    font-size: 14px;
    margin-top: 25px;
    margin-bottom: 30px;
}
.by-btn{
    background-color:rgba(217, 78, 128, 1);
    padding:  11px 24px;
    border-radius: 8px;
    color: #fff;
    margin-right: 10px;
    cursor: pointer;
    text-decoration: unset;
    margin-right: 15px;
}
.by-btn:hover{
    background: #b81b55;
}
.read-btn{
    background-color:transparent;
    padding:  11px 24px;
    color: #fff;
    border-radius: 8px;
    border: 1px solid rgba(153, 153, 153, 0.536);
    cursor: pointer;
    text-decoration: unset;
}

.read-btn:hover{
    background: #6f7386;
}

.explor-head{
    position: relative;
    z-index: 2;
    padding-top: 50px;
}
.explor-head::before{
    position: absolute;
    content: "";
    top: -140px;
    left: 50%;
    right: 50%;
    transform: translate(-50% , -50%);
    width: 140px;
    height: 140px;
    background: #c71a93cf;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    filter: blur(90px);
}
/* @media only screen and (max-width:792px){
    .explor-head::before{
        left: 33%;
        top: -180px;

    }
} */

@media only screen and (max-width:1600px){
    /* .explor-head::before{
        left: 33%;
        top: -180px;

    } */
}


.blank-div{
    height: 100px;
    color: #fff;
    position: relative;
    top:-50px;
    z-index: 9999d;
    margin-bottom: -50px;
    background-image:linear-gradient(to top,#000 10%, #c71a9300) ;
    
}
#user{
    width: 65px;
}