.footer{
   background-color: rgba(20, 20, 28, 1);
   padding: 50px;
   padding-top: 80px;
}
.footer-logo{
   height: 53px;
}
.social-logo{
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.social-logo img{
   width: 32px;
   margin: 20px;
}
.footer-link{
   list-style: none;
   display: flex;
   align-items: center;
   justify-content: center;
}
.footer-link li{
  color: aliceblue;
  font-size: 16px;
  margin: 20px;
  cursor: pointer;
  text-align: center;
}
.footer-link li a{
   color: aliceblue;
   font-size: 16px;
   margin: 20px;
   cursor: pointer;
   text-align: center;
   text-decoration: unset;
 }