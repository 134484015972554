.heading-section{
    position: relative;
    margin-bottom: 50px !important;
}
.navBar{
    background-color:#0A0B11!important;
    height: 80px !important;
    padding-top: 8px;
}
.toolBar{
    padding-left: 88px;
    padding-right: 88px;
}
.sideNave{
    background:#0a0b11 !important;
    backdrop-filter: blur(8rem) !important;
    color: #A0A3C4 !important;
    height: 100vh;
    border-right: none !important;
    z-index: 9999 !important;
}
.divider{
    border-bottom: 1px solid #afb1c9a1;
    margin-top: 15px;
}
.fixed-items{
    /* position: fixed; */
    /* right: 90px; */
}
.desktop-items{
    position: relative;
    left: -12%;

}
.desktop-items-btn{
    color: rgb(155, 168, 173);
    background-color: transparent;
    margin: 5px 20px;
    font-size: 15px;
    cursor: pointer;
    border: none;
    text-decoration: unset;
}
.desktop-items-btn:hover{
color: #fff;
}
.desktop-items-btn:focus{
color: #fff;
}
.drawer-icons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto 20px;
}
.drawer-icons img{
    max-width: 40px;
    padding-top: 15px;
}
.mobile-items{
    text-decoration: unset;
    width: 100%;
    display: block;
    /* width: 115px; */
    /* height: 25px; */
    /* background: #4E9CAF; */
    padding: 20px;
    text-align: start;
    border-radius: 5px;
    color: white;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1rem;
    letter-spacing: 0.00938em;
    color: #9ba8ad;
    /* font-weight: bold; */
    /* line-height: 25px; */
}
.mobile-items:hover{
    background-color:#191c3b !important;
    color: #fff;
}
.mobile-items:focus{
    background-color:#191c3b !important;
    color: #fff;
}
.trade-btn{
    padding: 10px 17px;
    border-radius: 5px;
    background-color: transparent;
    color: #fff;
    margin: 0px 10px;
    cursor: pointer;
    border: 1px solid #a0a3c4;
    text-decoration: unset;

}
.trade-btn:hover{
    border-radius: 5px;
    background-color: #202020;
    color: #fff;
}

.connect-btn{
    padding: 8px 15px;
    border-radius: 5px;
    background-color: transparent;
    color: #fff;
    border: 1px solid ;
    position: relative;
    bottom: -2px;
    cursor: pointer;
    border: 1px solid #a0a3c4;
}
.connect-btn img{
   max-width: 18px;
   padding-top: 3px;
}
.connect-btn:hover{
    border-radius: 5px;
    background-color: #202020;
    color: #fff;
}
.dot-btn{
    padding: 8px 10px;
    border-radius: 5px;
    background-color: transparent;
    color: #fff;
    border: 2px solid #aaa;
    margin: 0px 6px;
}
.dot-btn:hover{
    border-radius: 5px;
    background-color: #202020;
    color: #fff;
}
.icon-div{
    border-right: 1px solid rgb(119, 113, 113);
    padding-right: 8px;
}
/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    background-color: #0a0b11 !important;
    margin-left: -4px !important;
    border: 1px solid #C6CDD8 !important;
    margin-top: 10px;
    padding: 0 20px;
} */
/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    color: #C6CDD8 !important;
} */
.avalance-btn{
    background-color: transparent;
    color: #C6CDD8 !important;
    display: flex;
    align-items: center;
    font-size: 16px;
    border: none;
    margin-left: -5px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
.avalance-btn img{
    width: 20px;
    margin-right: 5px;
}
.language-btn{
    background-color: transparent;
    border: 1px solid rgb(142, 142, 142);
    color: rgb(184, 180, 180);
    width: 90%;
    padding: 7px;
    text-align: left;
    border-radius: 5px;
    font-size: 17px;
}
.modal-text{
    position: relative;
    color: #e1e1e1;
    top: -30px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(137, 122, 122);
}
.moodal-close-btn{
    position: absolute;
    width: 26px;
    top: 20px;
    right:40px;
    cursor: pointer;
}
.language-btn{
    cursor: pointer;
}
.language-btn span{
    position: relative;
    top: -5px;
    left: 10px;
}
.mobile-logo{
    position: relative;
    left: 50px;
    width: 50px;
}
@media only screen and (max-width:780px) {
   
    .fixed-items{
        position: fixed;
        right: 40px;
    }
}
@media only screen and (max-width:450px) {
    .mobile-logo{
        max-width: 40px !important;
        margin-left: -10px !important;
    }
    .hide-text{
        display: none;
    }
    .fixed-items{
        position: fixed;
        right: 20px;
    }
}

